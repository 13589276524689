@import url(https://fonts.googleapis.com/css?family=Poppins|Ubuntu&display=swap);
.example-fill-remaining-space {
    flex: 1 1 auto;
}

@media (max-width: 800px) {
    #myHeading { 
        font-size: 12px; 
    }
}
:root {
  --background: white;
  --text-primary: rgb(53, 52, 52);
  --text-secondary: royalblue;
  --accent: rgb(19, 85, 165);
}
[data-theme="dark"] {
  --background: rgb(61, 59, 59);
  --text-primary: rgb(231, 231, 231);
  --text-secondary: grey;
  --accent: rgb(33, 133, 208);
}
.App {
  color: rgb(53, 52, 52);
  color: var(--text-primary);
  background-color: white;
  background-color: var(--background);
  padding: 40px;
  height: 100vh;
  transition: all 0.2s;
}
.theme-button {
  color: rgb(53, 52, 52);
  color: var(--text-primary);
  background-color: white;
  background-color: var(--background);
  border: 1px rgb(53, 52, 52) solid;
  border: 1px var(--text-primary) solid;
  float: right;
  margin-top: -10px;
  transition: all 0.1s;

}

.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}

.toggle-theme-wrapper span {
  font-size: 18px;
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 40px;
}

.toggle-theme input {
  display: none;
}

.slider {
  background-color: #ccc;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.slider:before {
  background-color: #fff;
  content: "";
  height: 24px;
  left: 1px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
          transform: translateX(16px);
}

input:checked + .slider {
  background-color: cornflowerblue;
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #eee;
  font-family: "Ubuntu", sans-serif;
}

.box {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.our-services {
  margin-top: 75px;
  padding-bottom: 30px;
  padding: 50px 40px;
  min-height: 198px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--background);
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(174, 174, 174, 0.703);
}

.our-services .icon {
  padding-bottom: 65px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
}

.our-services:hover h4,
.our-services:hover p {
  color: #fff;
}

.wrapper:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(45deg, #454605 30%, #093979 90%);
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

