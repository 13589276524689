:root {
  --background: white;
  --text-primary: rgb(53, 52, 52);
  --text-secondary: royalblue;
  --accent: rgb(19, 85, 165);
}
[data-theme="dark"] {
  --background: rgb(61, 59, 59);
  --text-primary: rgb(231, 231, 231);
  --text-secondary: grey;
  --accent: rgb(33, 133, 208);
}
.App {
  color: var(--text-primary);
  background-color: var(--background);
  padding: 40px;
  height: 100vh;
  transition: all 0.2s;
}
.theme-button {
  color: var(--text-primary);
  background-color: var(--background);
  border: 1px var(--text-primary) solid;
  float: right;
  margin-top: -10px;
  transition: all 0.1s;

}

.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.toggle-theme-wrapper span {
  font-size: 18px;
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 40px;
}

.toggle-theme input {
  display: none;
}

.slider {
  background-color: #ccc;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.slider:before {
  background-color: #fff;
  content: "";
  height: 24px;
  left: 1px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

input:checked + .slider {
  background-color: cornflowerblue;
}

.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
