@import url("https://fonts.googleapis.com/css?family=Poppins|Ubuntu&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #eee;
  font-family: "Ubuntu", sans-serif;
}

.box {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.our-services {
  margin-top: 75px;
  padding-bottom: 30px;
  padding: 50px 40px;
  min-height: 198px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--background);
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(174, 174, 174, 0.703);
}

.our-services .icon {
  padding-bottom: 65px;
  transform: translateY(-50%);
  text-align: center;
}

.our-services:hover h4,
.our-services:hover p {
  color: #fff;
}

.wrapper:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(45deg, #454605 30%, #093979 90%);
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
